<template>
  <v-app>
    <v-main>
      <v-card
        data-error="internalservererror"
        flat
        tile
        max-width="1320"
        class="mx-auto px-16"
      >
        <v-container>
          <v-row dense no-gutters>
            <v-col>
              <v-img src="@/assets/logo500.svg" />
            </v-col>
            <v-col>
              <v-card flat tile width="100%">
                <div class="text-overline">
                  {{ $t("500.title") }}
                </div>
                <div class="cantata-one text-h1 grey--text text--lighten-2">
                  500
                </div>
                <div class="cantata-one text-h4 py-8">
                  {{ $t("500.headline") }}
                </div>
                <p class="text-body-1">
                  {{ $t("500.feedback") }}
                </p>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-main>
  </v-app>
</template>

<style scoped>
.cantata-one {
  font-family: "Cantata One", serif !important;
}
</style>

<script>
export default {
  name: "InternalServerError",
};
</script>
